<template>
  <div class="invoice-list">
    <CommonList
      ref="commonList"
      type="invoice"
      style="padding-bottom: 50px; overflow: auto"
      :selectTypeFilter="selectTypeFilter"
      @edit="onEdit"
    />
    <LayoutFooter />
  </div>
</template>

<script>
import CommonList from "@components/CommonList.vue";
import LayoutFooter from "@components/LayoutFooter.vue";

export default {
  name: "InvoceList",

  components: {
    CommonList,
    LayoutFooter,
  },

  data() {
    return {
      dataList: [],
      selectTypeFilter: "invoice",
    };
  },

  methods: {
    onEdit(id) {
      this.$router.push({
        name: "invoiceDetail",
        query: {
          id: id,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.invoice-list {
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
